import HelloSignEmbedded from 'hellosign-embedded';

import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


const storageKey = "store";
const store = localStorage.getItem(storageKey);

const parsedStore = JSON.parse(store);
const initialTranslationJSON = fetchTranslation(parsedStore).then(initializeApp);

function initializeApp(initialTranslationJSON) {
    const app = Elm.Main.init({
      node: document.getElementById('root'),
      flags: {
        width: window.innerWidth,
        height: window.innerHeight,
        serverUrl: process.env.ELM_APP_API_URL,
        clientId: process.env.ELM_APP_CLIENT_ID,
        authServerUrl: process.env.ELM_APP_AUTH_SERVER_URL,
        dataApiUrl: process.env.ELM_APP_DATA_API_URL,
        dataApiToken: process.env.ELM_APP_DATA_API_TOKEN,
        documentApiUrl: process.env.ELM_APP_DOCUMENT_API_URL,
        store: JSON.parse(store),
        translations: initialTranslationJSON
      }
    });

    const helloSignClient = new HelloSignEmbedded({ clientId: process.env.ELM_APP_HELLO_SIGN_CLIENT_ID });

    app.ports.openEmbeddedSigning.subscribe((signUrl) => {
      helloSignClient.open(signUrl, { skipDomainVerification: true });
    });

    helloSignClient.on('close', () => {
      console.log('The signature request window was closed');
      app.ports.onEmbeddedSigningEnded.send(false);
    });

    helloSignClient.on('finish', () => {
      app.ports.onEmbeddedSigningEnded.send(true);
    });

    helloSignClient.on('message', ({ type }) => {
      if (type === 'hellosign:initialize') helloSignClient.canBeClosed = true;
      if (type === 'hellosign:initialize' && helloSignClient.shouldBeClosed) setTimeout(() => helloSignClient.close(), 0);
    });

    app.ports.storeCache.subscribe(function(val) {
        if (val === null) {
            localStorage.removeItem(storageKey);
        }
        else {
            const oldCache = JSON.parse(localStorage.getItem(storageKey) ?? JSON.stringify({}));
            val = Object.assign({}, oldCache, val);
            localStorage.setItem(storageKey, JSON.stringify(val));
        }

        changeTranslation(val);

        setTimeout(function() { app.ports.onStoreChange.send(val); }, 0);
    });

    app.ports.genRandomBytes.subscribe(n => {
        const buffer = new Uint8Array(n);
        crypto.getRandomValues(buffer);
        const bytes = Array.from(buffer);
        localStorage.setItem("bytes", bytes);
        app.ports.randomBytes.send(bytes);
    });

    app.ports.changeSnackbarState.subscribe((notification) => {
        app.ports.onStateChange.send(notification);
    });

    window.addEventListener("storage", function(event) {
      if (event.storageArea === localStorage && event.key === storageKey) {
        app.ports.onStoreChange.send(JSON.parse(event.newValue));
      }

      const parsedStore = JSON.parse(event.newValue);
      changeTranslation(parsedStore);
    }, false);

    function changeTranslation(store) {
        const translationJSON = fetchTranslation(store)
            .then(app.ports.translationsChanged.send);
    }

    class HelloSign extends HTMLElement {
      constructor() {
        super();
        helloSignClient.shouldBeClosed = false;

        this.attachShadow({ mode: 'open' });
        const placeholder = document.createElement('div');
        this.shadowRoot.append(placeholder);
      }

      disconnectedCallback() {
        if (helloSignClient.canBeClosed) helloSignClient.close();
        helloSignClient.shouldBeClosed = true;
      }
    }

    customElements.define('hello-sign', HelloSign);

    //

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CR1A-PWA
    serviceWorker.unregister();
}

function fetchTranslation(store) {
    const lang = store?.lang ?? 'en';
    return fetch(`${process.env.PUBLIC_URL}/translations/${lang}.json`)
        .then(response => response.json());
}
